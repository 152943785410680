/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.main-div {
    background-color: #323d4c;
    background-color: #fff;
}

/*.list-group-item {*/
/*    height: 50px;*/
/*    text-align: center;*/
/*    font-size: 15px;*/
/*}*/

.contain-div {
    background-color: #fff;
}
.img-container {
    max-height: 200px;
    max-width: 300px;
    align-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
}

.carousel-control-next-icon-fake {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
}